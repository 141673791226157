@import "../../../scss/mixins";

.transfer-page-layout {
  display: flex;
  justify-content: center;
}

.transfer-menu {
  margin-top: 22.5px;
  min-height: 750px;
  width: 100%;
}

@include maxViewport("m") {
  .transfer-menu h1 {
    width: 90vw;
  }
}

.transfer-page {
  margin-top: 42.5px;
  min-height: 750px;
}

.transfer-fields .context-form-field:last-of-type {
  margin-bottom: var(--space-s);
}

.card .info-card {
  display: grid;
  grid-template-columns: 37fr 100fr;
  border-top: 1px solid var(--border-color-light);
  column-gap: 20px;
}

.card .info-card:first-of-type {
  border-top: 0;
}

.card .label {
  grid-column-start: 1;
  padding-top: 8px;
  padding-bottom: 8px;
  justify-self: start;
  font-size: 16px;
  font-weight: 600;
  line-height: 20px;
  color: var(--font-color-heading);
}

.card .value {
  text-align: right;
  grid-column-start: 2;
  justify-self: end;
  padding-top: 8px;
  padding-bottom: 8px;
  font-size: 16px;
  font-weight: 400;
  line-height: 20px;
  color: var(--font-color-primary);
  overflow-wrap: anywhere;
}

.back-icon {
  position: absolute;
  z-index: 1;
  text-align: left;
  margin-top: 12px;
  margin-left: 9px;
}
.back-icon:hover {
  cursor: pointer;
}

.form-section-header {
  margin-bottom: 20px;
  padding-bottom: 4px;
  font-size: 20px;
  font-weight: 700;
  border-bottom: 2px solid var(--theme-primary);
  color: RGBA(var(--nds-black));
}

.action-review-section-header {
  line-height: 28px;
  font-size: 16px;
  font-weight: 700;
  border-bottom: 2px solid var(--theme-primary);
}

.action-review-section-header.margined {
  margin-top: 40px;
}

.narmi-transfer-icon-container {
  position: relative;
  height: 55px;
  width: 49.2px;
  display: flex;
  width: 100%;
  justify-content: center;
  align-items: center;
}
.narmi-transfer-header-container {
  margin-top: 8px;
}

.narmi-icon-blob {
  position: absolute;
  color: RGBA(var(--theme-rgb-primary), var(--alpha-20));
  font-size: 55px;
  display: block;
}
.narmi-transfer-icon-container > .narmi-icon-transfer-arrows {
  position: absolute;
  font-size: 26px;
  display: block;
  color: var(--theme-primary);
}
.narmi-transfer-icon-container > .narmi-icon-m2m {
  position: absolute;
  font-size: 30px;
  display: block;
  margin-right: 2px;
  color: var(--theme-primary);
}
.narmi-transfer-icon-container > .narmi-icon-wires {
  position: absolute;
  font-size: 30px;
  display: block;
  margin-right: 2px;
  color: var(--theme-primary);
}

.narmi-transfer-icon-container > .narmi-icon-dollar-bill-line {
  position: absolute;
  font-size: 30px;
  display: block;
  margin-right: 2px;
  color: var(--theme-primary);
}

.transfer-cancel-button {
  margin-top: 12px;
}

.tooltip-info-icon {
  position: absolute;
  top: 24px;
  right: 16px;
  transform: translateY(-44%);
}

.has-tooltip-child {
  position: relative;
}

.radio-group-title {
  font-size: 16px;
  font-weight: 600;
  line-height: 20px;
  margin-bottom: 8px;
}

.zelle-iframe {
  background-color: var(--color-white);
  border: 0;
  height: 75%;
  max-width: 1198px;
  width: 75vw;
  overflow: hidden;
}

.loan-account-name-tag {
  font-size: var(--font-size-s);
  color: var(--theme-primary);
  background-color: RGBA(var(--theme-rgb-primary), var(--alpha-5));
  border-radius: 40px;
  padding: var(--space-xs) var(--space-l);
}

.loan-payment-form-card {
  margin-top: 32px;
  padding: var(--space-l);
  border-radius: var(--border-radius-s);
  box-shadow: var(--elevation-low);
  background-color: var(--color-white);
}

.loan-payment-form-card {
  @include minViewport("m") {
    margin-top: var(--space-xl);
  }
}

.wire-transfer-container {
  display: flex;
  flex-direction: column;
  padding: var(--space-xl) var(--space-l);
  .mobile-web-extra-space {
    max-width: 568px;
    width: 100%;
    display: flex;
    flex-direction: column;
    align-self: center;
  }
}

.wire-transfer-form-header {
  color: var(--font-color-heading);
  font-size: var(--font-size-m);
  font-weight: var(--font-weight-bold);
}

.form-header-with-icon {
  .narmi-transfer-icon-container {
    width: 50px;
    padding-top: 4px;
    .narmi-icon-solid-circle {
      font-size: 32px;
      color: RGB(var(--nds-lightest-grey));
    }
    .narmi-icon-wires {
      font-size: 20px;
      margin-left: 2px;
      margin-top: 0.5px;
    }
    .narmi-icon-chevron-left {
      font-size: 28px;
    }
  }
}

.wire-transfer-row:not(:first-child) {
  border-top: 1px solid RGBA(var(--nds-lightest-grey));
}

.wire-transfer-row:not(:last-child) {
  padding-bottom: var(--space-m);
}

.wire-transfer-row:not(:first-child) {
  .nds-row {
    padding-top: var(--space-m);
  }
}

.wire-transfer-row-name {
  font-weight: var(--font-weight-semibold);
}

.wire-transfer-row-value {
  text-align: right;
  max-width: 60%;
  overflow-wrap: break-word; /* Ensures long words break and wrap */
  word-break: break-word; /* Ensures words don't break awkwardly */
}

.wire-transfer-row-value-align-left {
  text-align: left;
}

.loan-payment-page {
  margin-left: var(--space-l);
  margin-right: var(--space-l);
  width: calc(100vw - var(--space-l) - var(--space-l) - 5px);
  max-width: 568px;

  h1 {
    font-size: var(--font-size-heading3);
    line-height: 30px;
  }
}

@include minViewport("m") {
  .loan-payment-page {
    width: 568px;
    margin-top: var(--space-l);
  }
  .wire-transfer-container {
    padding-top: var(--space-xxl);
  }

  .loan-payment-page {
    h1 {
      font-size: var(--font-size-heading1);
      line-height: 40px;
    }
  }
}

@include minViewport("l") {
  // .transfer-page contains .loan-payment-page and gets 40px padding, responsive-top-padding gets 18px, and we want 80px
  .loan-payment-page {
    margin-top: 22px;
  }
  // wires aren't contained by .transfer-page, so we need 62px
  .wire-transfer-container {
    padding-top: 62px;
  }
}

.form-buttons-with-spacing {
  // helper class to render buttons that are 40px below content on mobile and 60px below content on medium screens
  margin-top: var(--space-xl);
  @include minViewport("m") {
    margin-top: var(--space-xxl);
  }
}

.title-with-chevron {
  position: relative;
  left: -6px;
}

@include minViewport("m") {
  .title-with-chevron {
    left: -30px;
  }
}

.title-with-chevron .narmi-icon-chevron-left {
  font-size: 22px;
  margin-right: var(--space-xs);
}

.card-icon {
  width: 35px;
  height: 24px;
}

.loan-review-row {
  border-top: var(--border-size-s) solid var(--color-lightGrey);
  padding-bottom: var(--space-xs);
  padding-top: var(--space-xs);
}

.loan-review-row:first-of-type {
  border-top: none;
}

.loan-review-row:last-of-type {
  padding-bottom: 0;
}

.add-a-card-naf-app {
  padding: var(--space-l);
  background-color: var(--color-white);
}

.transfer-option-card {
  width: 100%;

  .nds-button-content {
    margin: 0 !important;
  }
}

@include minViewport("m") {
  .transfer-option-card {
    width: 274px;

    .transfer-card-content {
      min-height: 116px;

      > p {
        min-height: 3em;
      }
    }
  }
}

@include minViewport("l") {
  .transfer-option-card {
    .nds-button--menu {
      margin: 0px !important;
      width: 100%;
    }
  }
}

.transfer-card-content {
  padding: var(--space-l);
}

@include minViewport("l") {
  .transfer-card-content {
    min-height: 116px;
  }
}

[data-classname="AppAuthorize transfer-button"] {
  width: 100%;
}